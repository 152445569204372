<template>
  <div class="metrics__box">
    <template
      v-if="
        getUserType != 'medic' &&
        getUserType != 'abc' &&
        getUserType != 'manager_abc' &&
        isAllClinicsView
      ">
      <div
        class="h-100 metrics__box__card"
        v-for="(metricData, index) in metricsData"
        :key="index"
        :class="{
          'metrics__box__card--one active-card': index == 0,
          '': index != 0,
        }">
        <template v-if="index == 0">
          <div class="metrics__box__card__info metrics__box__card__info--one">
            <p>{{ metricData.name }}</p>
            <p>{{ metricData.total }}</p>
          </div>
          <div
            class="
              metrics__box__card__percentage metrics__box__card__percentage--one
            ">
            <p>
              <font-awesome-icon size="lg" :icon="['fa', 'caret-up']" />
              {{ metricData.total_percentage + "%" }}
            </p>
          </div>
        </template>
        <template v-else>
          <div class="metrics__box__card__info fs-2">
            <p class="metrics__box__card__title">
              {{ metricData.name }}
            </p>
            <hr />
            <div class="metrics__box__card__content">
              <div
                class="
                  d-flex
                  justify-content-between
                  align-items-center
                  fs-4
                  mb-2
                ">
                <div>Online</div>
                <div>
                  <span class="metrics__box__card__value">{{
                    metricData.online
                  }}</span>
                  <span
                    class="
                      metrics__box__card__percentage
                      metrics__box__card__percentage--all
                      ms-3
                    ">
                    <font-awesome-icon size="lg" :icon="['fa', 'caret-up']" />
                    {{ metricData.online_percentage + "%" }}
                  </span>
                </div>
              </div>
              <div
                class="
                  d-flex
                  justify-content-between
                  align-items-center
                  fs-4
                  mb-2
                ">
                <div>Primera visita</div>
                <div>
                  <span class="metrics__box__card__value">{{
                    metricData.first_visit
                  }}</span>
                  <span class="metrics__box__card__percentage ms-3">
                    <font-awesome-icon size="lg" :icon="['fa', 'caret-up']" />
                    {{ metricData.first_visit_percentage + "%" }}
                  </span>
                </div>
              </div>
              <div
                class="
                  d-flex
                  justify-content-between
                  align-items-center
                  fs-4
                  mb-2
                ">
                <div>Segunda visita</div>
                <div>
                  <span class="metrics__box__card__value">{{
                    metricData.second_visit
                  }}</span>
                  <span class="metrics__box__card__percentage ms-3">
                    <font-awesome-icon size="lg" :icon="['fa', 'caret-up']" />
                    {{ metricData.second_visit_percentage + "%" }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </template>
    <template v-else>
      <template v-for="(metricData, index) in metricsData">
        <template v-if="metricData.id == getClinic">
          <div
            :key="index + 'Total'"
            class="
              h-100
              metrics__box__card metrics__box__card--one
              active-card
            ">
            <div class="metrics__box__card__info metrics__box__card__info--one">
              <p>Total Confirmadas</p>
              <p>{{ metricData.total }}</p>
            </div>
            <div
              class="
                metrics__box__card__percentage
                metrics__box__card__percentage--one
              ">
              <p>
                <font-awesome-icon size="lg" :icon="['fa', 'caret-up']" />
                {{ metricData.total_percentage + "%" }}
              </p>
            </div>
          </div>
          <div
            :key="index + 'Online'"
            class="h-100 metrics__box__card metrics__box__card--one">
            <div class="metrics__box__card__info metrics__box__card__info--one">
              <p>Online</p>
              <p>{{ metricData.online }}</p>
            </div>
            <div
              class="
                metrics__box__card__percentage
                metrics__box__card__percentage--one
              ">
              <p>
                <font-awesome-icon size="lg" :icon="['fa', 'caret-up']" />
                {{ metricData.online_percentage + "%" }}
              </p>
            </div>
          </div>
          <div
            :key="index + 'First'"
            class="h-100 metrics__box__card metrics__box__card--one">
            <div class="metrics__box__card__info metrics__box__card__info--one">
              <p>Primera visita</p>
              <p>{{ metricData.first_visit }}</p>
            </div>
            <div
              class="
                metrics__box__card__percentage
                metrics__box__card__percentage--one
              ">
              <p>
                <font-awesome-icon size="lg" :icon="['fa', 'caret-up']" />
                {{ metricData.first_visit_percentage + "%" }}
              </p>
            </div>
          </div>
          <div
            :key="index + 'Second'"
            class="h-100 metrics__box__card metrics__box__card--one">
            <div class="metrics__box__card__info metrics__box__card__info--one">
              <p>Segunda visita</p>
              <p>{{ metricData.second_visit }}</p>
            </div>
            <div
              class="
                metrics__box__card__percentage
                metrics__box__card__percentage--one
              ">
              <p>
                <font-awesome-icon size="lg" :icon="['fa', 'caret-up']" />
                {{ metricData.second_visit_percentage + "%" }}
              </p>
            </div>
          </div>
        </template>
      </template>
    </template>
  </div>
</template>

<script>
import "vue2-datepicker/index.css";
import { mapGetters } from "vuex";

export default {
  name: "MetricsCard",
  props: {
    metricsData: {
      type: Array,
      required: false,
    },
    isAllClinicsView: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    ...mapGetters(["getUserType", "getClinic"]),
  },
};
</script>
